// biome-ignore lint/style/useImportType: <explanation>
import React, { useEffect, useState } from 'react'

import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './SchedulePickup.i18n'
import { z } from 'zod'
import FormSimple, {
    type OnError,
    type FieldConfig,
    type SchemaField,
    SchemaRefineConfigurations,
} from '@/app/ui-new/components/Form/FormSimple'
import type { Pickup, Timezone, Country as CountryList, ServiceLevel } from '@/app/model'
import { type TransportType, TransportType as TransportTypeEnum, type BillDutiesToParty, type FedexCarrierCode, type Currency, ApiServiceAction } from '@lazr/enums'

import type { HandlingUnitFormProps } from '@/app/ui-new/components/Form/FullComponents/HandlingUnitForm/HandlingUnitForm'
import DoubleAddressForm from '@/app/ui-new/components/Form/FullComponents/DoubleAddressForm/DoubleAddressForm'
import type { AddressFormProps } from '@/app/ui-new/components/Form/FullComponents/AddressForm/AddressForm'
import type { Carrier, UuidV4 } from '@lazr/openapi-client'
import { processAddress } from '../../helpers'
import { capitalizeEachWord } from '../../../helpers'
import AccordionSession from '@/app/ui-new/components/Accordion/AccordionSession'

import MultipleHandlingUnitForm from '@/app/ui-new/components/Form/FullComponents/MultipleHandlingUnitForm/MultipleHandlingUnitForm'
import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import { flattenSchema } from '@/app/ui-new/components/Form/helpers'
import type { ProcessDataProps } from '../../New/processData'
import type { ReturnSchedulePickupDataProps, ReturnSchedulePickupProps } from '../../hooks/useSchedulePickup'
import {  Grid } from '@material-ui/core'

import { useHistory } from 'react-router'
import {
    Dialog,
    DialogTitle,
    DialogText,
    DialogButtonContainer
} from '@/app/ui-new/components/Dialog/Dialog'

export interface SchedulePickupProps {
    nameID: string
    defaultPickup: PickupDefaultProps
    pickup?: Pickup
}

export interface PickupDefaultProps {
    nameID: string
    carriers?: Carrier[]
    transportTypesData: {
        icon: string,
        id: TransportType,
        name: string
    }[]
    billDutiesToPartyData: []
    fedexLocationData: []
    fedexCarrierCodeData: []
    currencyCodeData: []
    timezoneList: Timezone[]
    countries?: CountryList[]
    processData: {
        fn: ProcessDataProps['fn']
        setReturnProcessData: ProcessDataProps['setReturnProcessData']
    }
    setCarrierId?: React.Dispatch<React.SetStateAction<any>>
    setTransportType?: React.Dispatch<React.SetStateAction<TransportType | undefined>>
    serviceLevel?: ServiceLevel[]
    schedulePickupData?: ReturnSchedulePickupDataProps
}

export enum SchedulePickupCarrier {
    CANADAPOST = 'CANADA POST',
    DHL = 'DHL',
    FEDEX = 'FEDEX',
    PUROLATOR = 'PUROLATOR',
    UPS = 'UPS',
    NATIONEX = 'NATIONEX',
}


const SchedulePickup: React.FunctionComponent<SchedulePickupProps> = ({ nameID, defaultPickup }) => {
    const { t } = useI18n(i18n)
    const history = useHistory()
    const [openDialog, setOpenDialog] = useState<boolean>(false)


    const [carrierName, setCarrierName] = useState<SchedulePickupCarrier | undefined>()

    const [carrierId, setCarrierId] = useState<string>()
    const [expanded, setIsExpanded] = useState<boolean>(true)

    const handleExpansion = () => {
        setIsExpanded((prevExpanded) => !prevExpanded)
    }

    const [quantityHandlingUnit, setQuantityHandlingUnit] = useState<boolean>(false)
    const [lengthHandlingUnit, setLengthHandlingUnit] = useState<boolean>(false)
    const [widthHandlingUnit, setWidthHandlingUnit] = useState<boolean>(false)
    const [heightHandlingUnit, setHeightHandlingUnit] = useState<boolean>(false)
    const [avarageWeightHandlingUnit, setAvarageWeightHandlingUnit] = useState<boolean>(false)
    const [weightHandlingUnit, setWeightHandlingUnit] = useState<boolean>(false)
    const [hideHandlingUnit, setHideHandlingUnit] = useState<boolean>(true)
    const [hideSpecifics, setHideSpecifics] = useState<boolean>(false)
    const [pickupAddress, setPickupAddress] = useState<AddressFormProps['address'] | undefined>()
    const [shipperAddress, setShipperAddress] = useState<AddressFormProps['address'] | undefined>()
    const [handlingUnitData, setHandlingUnitData] = useState([])
    const [defaultData, setDefaultData] = useState([])
    const [customSpecificsData, setCustomSpecificsData] = useState([])
    const [forceSubmit, setForceSubmit] = useState<boolean>(false)

    const initialIsValid: OnError = {
        data: {},
        isValid: false,
        isDirty: false,
    }
    const [isValidHandling, setIsValidHandling] = useState<OnError>(initialIsValid)
    const [isValidAddress, setIsValidAddress] = useState<OnError>(initialIsValid)
    const [isValidDefaultField, setIsValidDefaultField] = useState<OnError>(initialIsValid)
    const [isValidCustomSpecifics, setIsValidCustomSpecifics] = useState<OnError>(initialIsValid)

    const yesSchema: Record<string, SchemaField> = {
        yes: {
            type: 'checkbox',
            label: t('Yes'),
            layoutConfig: { xs: 12 },
        },
    }

    interface Carrier {
        carrier: SchedulePickupCarrier
        layoutConfig?: any
    }

    // Reusable fields
    const isResidential = ({ carrier, layoutConfig }: Carrier): FieldConfig[] => [
        {
            name: 'isResidential',
            hide: carrierName !== carrier,
            schema: z.any(),
            groupInputs: flattenSchema(yesSchema, 'layoutConfig,label,type'),
            type: 'checkbox',
            hasOrder: false,
            required: true,
            values: '',
            layoutConfig: { xs: 12, sm: 12, ...layoutConfig },
            labelName: t('Is Residential?'),
        },
    ]

    const quantity = ({ carrier, layoutConfig }: Carrier): FieldConfig[] => [
        {
            name: 'quantity',
            hide: carrierName !== carrier,
            labelName: t('Quantity'),
            required: true,
            schema: carrierName === carrier ? z.string().min(1, { message: `${t('Quantity')}  ${t('is required')}` }) : z.any(),
            type: 'numberField',
            values: '',
            layoutConfig: { xs: 12, sm: 6, ...layoutConfig },
        },
    ]

    const serviceLevelId = ({ carrier, layoutConfig }: Carrier): FieldConfig[] => {
        return [
            {
                name: 'serviceLevelId',
                hide: carrierName !== carrier,
                labelName: t('Service Level Code'),
                required: true,
                schema:
                    carrierName === carrier ? z.string().min(1, { message: `${t('Service Level Code')}  ${t('is required')}` }) : z.any(),
                type: 'select',
                values: '',
                layoutConfig: { xs: 12, sm: 12, ...layoutConfig },
                select: {
                    data: defaultPickup.serviceLevel?.filter(value => typeof value !== 'boolean'),
                    size: 'small',
                    name: 'serviceLevel'
                },
            },
        ]
    }

    const filteredTransportType = (): PickupDefaultProps['transportTypesData'] => {
        switch (carrierName) {
            case SchedulePickupCarrier.CANADAPOST:
                return defaultPickup.transportTypesData.filter((transportType) =>
                    transportType.id === TransportTypeEnum.PARCEL
                )

            case SchedulePickupCarrier.DHL:
                return defaultPickup.transportTypesData.filter((transportType) =>
                    transportType.id === TransportTypeEnum.PARCEL ||
                    transportType.id === TransportTypeEnum.ENVELOPE
                )

            case SchedulePickupCarrier.PUROLATOR:
                return defaultPickup.transportTypesData.filter((transportType) =>
                    transportType.id === TransportTypeEnum.PARCEL ||
                    transportType.id === TransportTypeEnum.PAK ||
                    transportType.id === TransportTypeEnum.ENVELOPE ||
                    transportType.id === TransportTypeEnum.EXPRESS_BOX
                )

            case SchedulePickupCarrier.FEDEX:
            case SchedulePickupCarrier.UPS:
                return defaultPickup.transportTypesData.filter((transportType) =>
                    transportType.id === TransportTypeEnum.PARCEL ||
                    transportType.id === TransportTypeEnum.PAK ||
                    transportType.id === TransportTypeEnum.ENVELOPE ||
                    transportType.id === TransportTypeEnum.EXPRESS_BOX ||
                    transportType.id === TransportTypeEnum.TUBE
                )

            default:
                return defaultPickup.transportTypesData.filter((transportType) =>
                    transportType.id === TransportTypeEnum.PARCEL ||
                    transportType.id === TransportTypeEnum.PAK ||
                    transportType.id === TransportTypeEnum.ENVELOPE
                )
        }
    }

    // DHL FIELD
    const fieldsDHLSpecifics: FieldConfig[] = [
        ...isResidential({ carrier: SchedulePickupCarrier.DHL }),
        {
            name: 'dutyBillToAccountNumber',
            hide: carrierName !== SchedulePickupCarrier.DHL,
            labelName: t('Bill To Account Number'),
            required: true,
            schema:
                carrierName === SchedulePickupCarrier.DHL
                    ? z.string().min(1, { message: `${t('Bill To Account Number')}  ${t('is required')}` })
                    : z.any(),
            type: 'text',
            values: '',
            layoutConfig: { xs: 12, sm: 6 },
        },
        ...serviceLevelId({ carrier: SchedulePickupCarrier.DHL, layoutConfig: { xs: 12, sm: 6 } }),
        {
            name: 'totalDeclaredValue',
            hide: carrierName !== SchedulePickupCarrier.DHL,
            labelName: t('Total Declared Value'),
            required: true,
            schema:
                carrierName === SchedulePickupCarrier.DHL
                    ? z.string().min(1, { message: `${t('Total Declared Value')}  ${t('is required')}` })
                    : z.any(),
            type: 'text',
            values: '',
            layoutConfig: { xs: 12, sm: 6 },
        },
        {
            name: 'totalDeclaredValueCurrency',
            hide: carrierName !== SchedulePickupCarrier.DHL,
            labelName: t('Currency'),
            required: true,
            schema:
                carrierName === SchedulePickupCarrier.DHL
                    ? z.string().min(1, { message: `${t('Currency')}  ${t('is required')}` })
                    : z.any(),
            type: 'select',
            select: {
                name:'currency',
                data: defaultPickup.currencyCodeData,
                size: 'small',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 6 },
        },
    ]

    // UPS FIELD
    const fieldsUPSSpecifics: FieldConfig[] = [
        ...isResidential({ carrier: SchedulePickupCarrier.UPS }),
        ...serviceLevelId({ carrier: SchedulePickupCarrier.UPS }),
    ]

    // CANADA POST FIELDS
    const fieldsCanadaPostSpecifics: FieldConfig[] = [...quantity({ carrier: SchedulePickupCarrier.CANADAPOST })]

    // Fedex
    const fieldsFedexSpecifics: FieldConfig[] = [
        {
            name: 'destinationDeliveryInstructions',
            hide: carrierName !== SchedulePickupCarrier.FEDEX,
            labelName: t('Destination Delivery Instructions'),
            required: true,
            schema:
                carrierName === SchedulePickupCarrier.FEDEX
                    ? z.string().min(1, { message: `${t('Destination Delivery Instructions')}  ${t('is required')}` })
                    : z.any(),
            type: 'textOutlined',
            values: '',
            layoutConfig: { xs: 12, sm: 6 },
        },
        {
            name: 'location',
            hide: carrierName !== SchedulePickupCarrier.FEDEX,
            labelName: t('Location'),
            required: true,
            schema:
                carrierName === SchedulePickupCarrier.FEDEX
                    ? z.string().min(1, { message: `${t('Location')}  ${t('is required')}` })
                    : z.any(),
            type: 'select',
            select: {
                data: defaultPickup.fedexLocationData,
                size: 'small',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 6 },
        },

        ...serviceLevelId({ carrier: SchedulePickupCarrier.FEDEX, layoutConfig: { xs: 12, sm: 6 } }),
        {
            name: 'carrierCode',
            hide: carrierName !== SchedulePickupCarrier.FEDEX,
            labelName: t('Carrier Code'),
            required: true,
            schema:
                carrierName === SchedulePickupCarrier.FEDEX
                    ? z.string().min(1, { message: `${t('Carrier Code')}  ${t('is required')}` })
                    : z.any(),
            type: 'select',
            select: {
                data: defaultPickup.fedexCarrierCodeData,
                size: 'small',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 6 },
        },
    ]

    //Nationex
    const fieldsNationexSpecifics: FieldConfig[] = [...quantity({ carrier: SchedulePickupCarrier.NATIONEX })]

    const customSpecifics: FieldConfig[] = [
        ...fieldsDHLSpecifics,
        ...fieldsUPSSpecifics,
        ...fieldsCanadaPostSpecifics,
        ...fieldsFedexSpecifics,
        ...fieldsNationexSpecifics,
    ]

    const initHandlingUnitField: HandlingUnitFormProps['fields'] = {
        quantity: { hide: quantityHandlingUnit, label: t('Quantity'), subLabel: t('Packages') },
        length: { hide: lengthHandlingUnit },
        width: { hide: widthHandlingUnit },
        height: { hide: heightHandlingUnit },
        avarageWeight: { hide: avarageWeightHandlingUnit },
        weight: { hide: weightHandlingUnit },
        totalWeight: { label: `\u00A0${t('Total Weight')} \u00A0(${t('All packages')})`, layoutConfig: { id: 'totalWeight' }, hide: false },
    }

    const getCustomSpecificsError = (data: OnError) => {
        if (!hideSpecifics) {
            setIsValidCustomSpecifics(data)
        }
    }

    const getHandlingUnitError = (data: OnError) => {
        if (!hideHandlingUnit) {
            setIsValidHandling(data)
        }
    }

    const getDefaultFieldsError = (data: OnError) => {
        setIsValidDefaultField(data)
    }

    const getAddressDataError = (data: OnError) => {
        setIsValidAddress(data)
    }

    const getCustomSpecifics = (data: any) => {
        if (data) {
            setCustomSpecificsData(data)
            const isValid = isValidCustomSpecifics
            isValid.isValid = true
            isValid.isDirty = true
            setIsValidCustomSpecifics(isValid)
        }
    }
    const getHandlingUnit = (data: any) => {
        if (data.length > 0) {
            const isValid = isValidHandling
            isValid.isValid = true
            isValid.isDirty = true
            setHandlingUnitData(data)
            setIsValidHandling(isValid)
        }
    }
    // customSpecificsData
    // handlingUnitData
    // defaultData

    const handleDefaultFieldsChange = (data: any) => {
        if (data) {
            setDefaultData(data)
            defaultPickup.setTransportType?.(data.transportType)
            const isValid = isValidDefaultField
            isValid.isValid = true
            isValid.isDirty = true
            setIsValidDefaultField(isValid)
        }
    }

    const getAddressData = ({ address1, address2 }: { address1: AddressFormProps['address']; address2: AddressFormProps['address'] }) => {
        setPickupAddress(address1)
        setShipperAddress(address2)
        const isValid = isValidAddress
        isValid.isValid = true
        isValid.isDirty = true
        setIsValidAddress(isValid)
    }

    const customFields: FieldConfig[] = [
        {
            name: 'CustomCarrier',
            schema: z.any(),
            hide: carrierName
                ? ![
                      SchedulePickupCarrier.CANADAPOST,
                      SchedulePickupCarrier.DHL,
                      SchedulePickupCarrier.FEDEX,
                      SchedulePickupCarrier.PUROLATOR,
                      SchedulePickupCarrier.UPS,
                      SchedulePickupCarrier.NATIONEX,
                  ].includes(carrierName)
                : true,
            type: 'injectComponent', /// TODO: change to custom Form
            injectComponent: (
                <AccordionSession
                    setIsExpanded={setIsExpanded}
                    handleExpansion={handleExpansion}
                    isExpanded={expanded}
                    title={capitalizeEachWord(carrierName)}
                    error={isValidCustomSpecifics.isDirty && !isValidCustomSpecifics.isValid}
                    completed={isValidCustomSpecifics.isDirty && isValidCustomSpecifics.isValid}
                    endLabel={isValidCustomSpecifics.isDirty && isValidCustomSpecifics.isValid ? t('completed') : ''}
                >
                    <Grid item container spacing={5}>
                        <Grid item xs={12}>
                            <FormSimple
                                hideForm={true}
                                nameID={`${nameID}-custom-specifics`}
                                fields={customSpecifics}
                                layoutConfig={{ spacing: 3 }}
                                forceSubmit={forceSubmit}
                                onChange={getCustomSpecifics}
                                onError={getCustomSpecificsError}
                                //resetForm={resetForm}
                                debouncer={{ wait: 1 }}
                            />
                        </Grid>
                        {hideHandlingUnit === false && (
                            <>
                                <MultipleHandlingUnitForm
                                    nameID='multipleHandling'
                                    hasMultiple={false}
                                    isAvarage={true}
                                    initHandlingUnitField={initHandlingUnitField}
                                    forceSubmit={forceSubmit}
                                    getError={getHandlingUnitError}
                                    getData={getHandlingUnit}
                                />
                            </>
                        )}
                    </Grid>
                </AccordionSession>
            ),
            layoutConfig: { xs: 12, sm: 12 },
        },
    ]

    const carrierFields: FieldConfig[] = [
        {
            name: 'carrierId',
            labelName: t('Select the carrier'),
            schema: z.string(),
            required: true,
            type: 'select',
            select: {
                data: defaultPickup.carriers,
                name: 'carrier',
                size: 'medium',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 12 },
        },
    ]

    const defaultFields: FieldConfig[] = [
        {
            name: 'transportType',
            labelName: t('Transport Type'),
            schema: z.string().min(1, { message: `${t('Transport Type')}  ${t('is required')}` }),
            required: true,
            type: 'select',
            select: {
                data: filteredTransportType(),
                name: 'iconList',
                size: 'small',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 6 },
        },
        {
            name: 'timezone',
            labelName: t('Timezone'),
            required: true,
            schema: z.string().min(1, { message: `${t('Timezone')}  ${t('is required')}` }),
            type: 'select',
            select: {
                data: defaultPickup.timezoneList,
                size: 'small',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 6 },
        },
        {
            name: 'openTime',
            labelName: t('Open Time'),
            required: true,
            schema: z.string().min(1, { message: `${t('Open Time')}  ${t('is required')}` }),
            type: 'timeField',
            select: {
                size: 'medium',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 4 },
        },
        {
            name: 'closeTime',
            labelName: t('Close Time'),
            required: true,
            schema: z.string().min(1, { message: `${t('Close Time')}  ${t('is required')}` }),
            type: 'timeField',
            select: {
                size: 'medium',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 4 },
        },
        {
            name: 'date',
            required: true,
            labelName: t('Date'),
            schema: z.string().refine(
                (dateStr) => {
                    const inputDate = new Date(dateStr)
                    const now = new Date()

                    // Set the time part to 0 for comparison to only compare the date part
                    now.setHours(0, 0, 0, 0)
                    inputDate.setHours(0, 0, 0, 0)

                    // Ensure the input date is today or later
                    return inputDate >= now
                },
                {
                    message: t('The date must be greater than the current date'),
                },
            ),
            type: 'date',
            select: {
                size: 'medium',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 4 },
        },

        ...customFields,
    ]

    /// Refine After Fields

    const schemaRefineDefaultFields: SchemaRefineConfigurations[] = [
        {
            refine: (data) => {
                const openTime = data.openTime
                const closeTime = data.closeTime

                if (!openTime || !closeTime) return true // Skip validation if one of the fields is missing

                // Helper function to validate time strings
                const isValidTime = (time: any) => /^(0?[1-9]|1[0-2]):[0-5][0-9] [APap][mM]$/.test(time)

                // Helper function to convert 12-hour format to 24-hour format
                const convertTo24Hour = (time: any) => {
                    const [timePart, modifier] = time.split(' ')
                    let [hours, minutes] = timePart.split(':').map(Number)

                    if (modifier.toLowerCase() === 'pm' && hours !== 12) {
                        hours += 12
                    }
                    if (modifier.toLowerCase() === 'am' && hours === 12) {
                        hours = 0
                    }

                    return { hours, minutes }
                }

                if (!isValidTime(openTime) || !isValidTime(closeTime)) {
                    return true // Return false if time format is invalid
                }

                const { hours: openHours, minutes: openMinutes } = convertTo24Hour(openTime)
                const { hours: closeHours, minutes: closeMinutes } = convertTo24Hour(closeTime)

                // Ensure that openTime is before closeTime
                return openHours < closeHours || (openHours === closeHours && openMinutes < closeMinutes)
            },
            options: {
                message: t('Open Time must be earlier than Close Time'),
                path: ['openTime'],
            }, //
        },
    ]
    const handleCarrierChange = (value: any) => {
        let carrier: SchedulePickupCarrier | undefined

        setIsValidHandling(initialIsValid)
        setIsValidAddress(initialIsValid)
        setIsValidDefaultField(initialIsValid)
        setIsValidCustomSpecifics(initialIsValid)
        defaultPickup?.setCarrierId?.(value.carrierId)
        // let unitFormProps: HandlingUnitFormProps['fields'];
        setCarrierId(value.carrierId)
        switch (value.carrierId) {
            case '1866f55f-5295-4c1c-b4db-43431f76194b':
                carrier = SchedulePickupCarrier.CANADAPOST
                setHideSpecifics(true)
                setHideHandlingUnit(true)
                setIsValidHandling({
                    data: {},
                    isValid: true,
                    isDirty: true,
                })

                break
            case 'd20f29c7-9282-4734-adf4-3a3523cd071a':
                carrier = SchedulePickupCarrier.FEDEX
                setHideHandlingUnit(false)

                setWeightHandlingUnit(false)
                setQuantityHandlingUnit(false)
                setLengthHandlingUnit(true)
                setWidthHandlingUnit(true)
                setHeightHandlingUnit(true)
                setAvarageWeightHandlingUnit(false)

                break
            case '04d1e42f-f1ab-44ff-ae3b-3570c5d748ae':
                carrier = SchedulePickupCarrier.DHL
                setHideHandlingUnit(false)

                setWeightHandlingUnit(false)
                setQuantityHandlingUnit(false)
                setLengthHandlingUnit(false)
                setWidthHandlingUnit(false)
                setHeightHandlingUnit(false)
                setAvarageWeightHandlingUnit(false)

                break
            case '34b047b3-9273-46e4-ab44-59f41ad62309':
                carrier = SchedulePickupCarrier.NATIONEX
                setHideSpecifics(false)
                setHideHandlingUnit(true)
                setIsValidHandling({
                    data: {},
                    isValid: true,
                    isDirty: true,
                })
                break
            case '8a7ac6d7-87a3-44d9-9f4f-a3cc566d5575':
                carrier = SchedulePickupCarrier.PUROLATOR
                setHideHandlingUnit(false)
                setHideSpecifics(true)

                setIsValidCustomSpecifics({
                    data: {},
                    isValid: true,
                    isDirty: true,
                })

                setWeightHandlingUnit(false)
                setQuantityHandlingUnit(false)
                setLengthHandlingUnit(true)
                setWidthHandlingUnit(true)
                setHeightHandlingUnit(true)
                setAvarageWeightHandlingUnit(false)
                // unitFormProps = purolatorSpecificsHandlingUnitField;
                break
            case '00c9abb2-f931-47fc-8c9a-f0e01cfbc886':
                carrier = SchedulePickupCarrier.UPS
                setHideHandlingUnit(false)

                setWeightHandlingUnit(false)
                setQuantityHandlingUnit(false)
                setLengthHandlingUnit(true)
                setWidthHandlingUnit(true)
                setHeightHandlingUnit(true)
                setAvarageWeightHandlingUnit(false)
                // unitFormProps = upsSpecificsHandlingUnitField;
                break
            default:
                break
        }

        setCarrierName(carrier)
    }

    const handleOnSubmit = () => {
        setForceSubmit(true)
        setForceSubmit(false)
        setOpenDialog(true)
        const data = {
            carrierId,
            carrierName,
            defaultData,
            customSpecificsData,
            handlingUnitData,
            pickupAddressData: pickupAddress,
            shipperAddressData: shipperAddress,
        }
        if (defaultPickup.countries)
            defaultPickup.processData.fn(
                data,
                defaultPickup.countries,
                defaultPickup.processData.setReturnProcessData,
                ApiServiceAction.NEW,
            )
    }

    return (
        <>
            <Grid item container spacing={5}>
                <Grid item xs={12}>
                    <AccordionSession
                        setIsExpanded={setIsExpanded}
                        handleExpansion={handleExpansion}
                        isExpanded={expanded}
                        title={t('Carrier')}
                        error={
                            !carrierName
                                ? false
                                :  isValidDefaultField.isDirty && !(isValidDefaultField.isValid && isValidCustomSpecifics.isValid && isValidHandling.isValid)
                        }
                        completed={
                            carrierName &&
                            isValidDefaultField.isDirty &&
                            isValidDefaultField.isValid &&
                            isValidCustomSpecifics.isDirty &&
                            isValidCustomSpecifics.isValid &&
                            isValidHandling.isDirty &&
                            isValidHandling.isValid
                        }
                        endLabel={
                            carrierName &&
                            isValidDefaultField.isDirty &&
                            isValidDefaultField.isValid &&
                            isValidCustomSpecifics.isDirty &&
                            isValidCustomSpecifics.isValid &&
                            isValidHandling.isDirty &&
                            isValidHandling.isValid
                                ? t('completed')
                                : ''
                        }
                    >
                        <Grid item container spacing={5}>
                            <Grid item xs={12}>
                                <FormSimple
                                    nameID={`${nameID}-form-carrier`}
                                    fields={carrierFields}
                                    forceSubmit={forceSubmit}
                                    layoutConfig={{ spacing: 3 }}
                                    onChange={handleCarrierChange}
                                    debouncer={{ wait: 1 }}
                                />
                            </Grid>
                            {carrierName && (
                                <Grid item xs={12}>
                                    <FormSimple
                                        nameID={`${nameID}-form-default fields`}
                                        fields={defaultFields}
                                        forceSubmit={forceSubmit}
                                        layoutConfig={{ spacing: 3 }}
                                        onChange={handleDefaultFieldsChange}
                                        onError={getDefaultFieldsError}
                                        debouncer={{ wait: 1 }}
                                        schemaRefineConfigurations={carrierName ? schemaRefineDefaultFields : undefined}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </AccordionSession>
                </Grid>
                <Grid item xs={12}>
                    {carrierName && (
                        <DoubleAddressForm
                            countries={defaultPickup.countries}
                            title='Address'
                            address1={{
                                data: processAddress(pickupAddress),
                                title: 'Pickup',
                            }}
                            address2={{
                                data: processAddress(shipperAddress),
                                title: 'Shipper',
                            }}
                            getData={getAddressData}
                            getError={getAddressDataError}
                            hasAddressSameAddress2={{ isSame: true, show: false }}
                            disabled={
                                isValidDefaultField.isDirty &&
                                isValidHandling.isDirty &&
                                isValidCustomSpecifics.isDirty &&
                                isValidDefaultField.isValid &&
                                isValidCustomSpecifics.isValid &&
                                isValidHandling.isValid
                                    ? false
                                    : true
                            }
                        />
                    )}
                    {/* isValidHandling: {isValidHandling.isDirty && isValidHandling.isValid ? 'true' : 'false'}
                    <br />
                    isValidAddress: {isValidAddress.isDirty && isValidAddress.isValid ? 'true' : 'false'}
                    <br />
                    isValidDefaultField: {isValidDefaultField.isDirty && isValidDefaultField.isValid ? 'true' : 'false'}
                    <br />
                    isValidCustomSpecifics: {isValidCustomSpecifics.isDirty && isValidCustomSpecifics.isValid ? 'true' : 'false'}
                    <br />
                    (isValidDefaultField.isDirty && isValidHandling.isDirty && isValidCustomSpecifics.isDirty) :{' '}
                    {!isValidDefaultField.isDirty || !isValidHandling.isDirty || !isValidCustomSpecifics.isDirty || !isValidAddress.isDirty
                        ? 'true'
                        : 'false'}
                    <br />
                    !isValidDefaultField.isValid || !isValidAddress.isValid || !isValidCustomSpecifics.isValid || !isValidHandling.isValid :
                    {!isValidDefaultField.isValid || !isValidAddress.isValid || !isValidCustomSpecifics.isValid || !isValidHandling.isValid
                        ? 'false'
                        : 'false'}
                    <br />
                    isValidAddress.isDirty: {isValidAddress.isDirty ? 'true' : 'false'} <br />
                    isValidDefaultField.isDirty: {isValidDefaultField.isDirty ? 'true' : 'false'} <br />
                    isValidCustomSpecifics.isDirty: {isValidCustomSpecifics.isDirty ? 'true' : 'false'} <br />
                    isValidHandling.isDirty: {isValidHandling.isDirty ? 'true' : 'false'} */}
                </Grid>
                <Grid item xs={12} container justifyContent='flex-end'>
                    <Grid item>
                        <PrimaryButton
                            onClick={() => {
                                handleOnSubmit()
                            }}
                            disabled={
                                openDialog ||
                                !isValidAddress.isDirty ||
                                !isValidDefaultField.isDirty ||
                                !isValidCustomSpecifics.isDirty ||
                                !isValidHandling.isDirty
                                    ? true
                                    : !isValidDefaultField.isValid ||
                                          !isValidAddress.isValid ||
                                          !isValidCustomSpecifics.isValid ||
                                          !isValidHandling.isValid
                                      ? true
                                      : false
                            }
                        >
                            {t('Schedule Pickup')}
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
            {defaultPickup?.schedulePickupData && (<Dialog
                size='small'
                open={openDialog}
            >
                <DialogTitle>
                    {
                        defaultPickup.schedulePickupData?.error ?
                        t('Schedule Pickup Failed') : t('Schedule Pickup Succeeded')
                    }
                </DialogTitle>
                <DialogText mt={4} mb={2}>
                    {
                        /* eslint-disable-next-line max-len */
                        defaultPickup.schedulePickupData?.error ?
                        t('There is an error occurred in the process, please contact support for solution.')
                        :
                        t('Congrats! You have successfully placed your schedule pickup.')
                    }
                </DialogText>
                <DialogButtonContainer centered>
                    <PrimaryButton
                        size='medium'
                        onClick={() => {
                            if (defaultPickup.schedulePickupData?.error) {
                                setOpenDialog(false)
                            } else {
                                history.push('/beta/schedule-pickup/')
                            }
                        }}
                    >
                        {t('Close')}
                    </PrimaryButton>
                </DialogButtonContainer>
            </Dialog>)}
        </>
    )
}

export default SchedulePickup
